import {SortableContainer, SortableElement, sortableHandle} from "react-sortable-hoc";
import EditableText from "@/components/resume/core/EditableText";
import React from "react";
import {IconPickerItem} from "react-icons-picker";


export const DragHandle = sortableHandle(() => {
    return (
        <span
            className="dragHandle"
            onMouseDown={(e) => {
                e.preventDefault(); // Prevent default behavior
                e.stopPropagation();
            }}
        >
            &#x2630;
        </span>
    );
});



export const SortableItem = SortableElement(({item, prefs, handleChange}) => (
    <li className={'sortable-item'}>
        <div className={"content"}>
            {
                prefs[`${item.type}Icon`].value &&
                <IconPickerItem value={prefs[`${item.type}Icon`].value} />
            }
            <EditableText text={item.value || ''} onChange={(value) => handleChange(item.type, value)}/>
            <DragHandle/>
        </div>
    </li>
));

export const SortableList = SortableContainer(({items, prefs, handleChange}) => (
    <ul className={'sortable-list'}>
        {items.map((item, index) => (
            prefs[`show${item.type.charAt(0).toUpperCase() + item.type.slice(1)}`].value && (
                <SortableItem key={item.type} index={index} item={item} prefs={prefs} handleChange={handleChange}/>
            )
        ))}
    </ul>
));

export default SortableList;