'use client';

import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, Form, Container, Row, Col, Offcanvas } from 'react-bootstrap';
import ThemeSwitcher from '@/components/config/ThemeSwitcher';
import FontSwitcher from '@/components/config/FontSwitcher';
import PDFOptionsSwitcher from '@/components/config/PDFOptionsSwitcher';
import ListStyleSwitcher from '@/components/config/ListStyleSwitcher';
import ClearLocalStorageButton from '@/components/config/ClearLocalStorageButton';
import {FaBars, FaTimes} from "react-icons/fa";
import BuyMeACoffeeButton from "@/components/structure/BuyMeACoffeeButton";
import ThemeColorPicker from "@/components/config/ThemeColorPicker";
// @ts-ignore
import IconPicker from 'react-icons-picker';
import DownloadTemplates from "@/components/config/DownloadTemplates";

interface ToolbarProps {
    pdfOption: string;
    setPDFOption: (option: string) => void;
    toggleDragHandles: () => void;
    icon: string,
    setIcon: (icon: string)=>void
}

const ToolbarContent: React.FC<ToolbarProps> = ({ pdfOption, setPDFOption, toggleDragHandles, icon, setIcon }) => (
    <Form>
        <Row className="align-items-start flex-column">
            <Col className="mb-2 toolbar-col">
                <DownloadTemplates />
            </Col>
            <Col className="mb-2 toolbar-col">
                {/*<ThemeSwitcher />*/}
                <ThemeColorPicker />
            </Col>
            <Col className="mb-2 toolbar-col">
                <FontSwitcher />
            </Col>
            <Col className="mb-2 toolbar-col">
                <PDFOptionsSwitcher currentOption={pdfOption} onChangeOption={setPDFOption} />
            </Col>
            <Col className="mb-2 toolbar-col">
                <ButtonGroup vertical>
                    <Button variant="outline-secondary" onClick={toggleDragHandles}>Toggle Drag Handles</Button>
                </ButtonGroup>

                <h5 style={{paddingTop: "10px"}}>Get Icon Value:</h5>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <IconPicker
                        hideSearchBar={false}
                        hideLibraryDropdown={true}
                        value={icon}
                        onChange={(newV: any) => {
                            setIcon(newV)
                        }}
                    />
                    <span>{icon}</span>
                </div>
            </Col>
            <Col className="mb-2 toolbar-col">
                <ClearLocalStorageButton/>
            </Col>
        </Row>
    </Form>
);

const RenderRazerPayButton = () => {
    const rzpPaymentForms = document.getElementsByClassName("rzp_payment_form");

    Array.from(rzpPaymentForms).forEach((form) => {
        if (!form.hasChildNodes()) {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/payment-button.js";
            script.async = true;
            script.dataset.payment_button_id = "pl_PG73oH5k1JHkRa";
            form.appendChild(script);
        }
    });
}

const Toolbar: React.FC<ToolbarProps> = ({pdfOption, setPDFOption, toggleDragHandles }) => {
    const [show, setShow] = useState(false);
    const [icon, setIcon] = useState("");

    const toggleShow = () => setShow(!show);
    useEffect(() => {
        document.querySelectorAll<HTMLUListElement>('ul').forEach((ul) => {
            ul.classList.add("hidden");
        });
        RenderRazerPayButton();
    }, [show]);


    return (
        <>
            {!show ?
                <>
                    <div className={"toobar-header"}>
                        <a className="navbar-brand" href="/">
                            <img className="navbar-brand-light" src="/images/brand/dark.svg" alt="Logo dark"/>
                        </a>
                        <Button variant="dark" size={"sm"} className={"ms-auto"}
                                style={{margin: '3px 0px 0 0', padding: '5px 10px', borderRadius: '10px', height: "40px"}}
                                onClick={toggleShow}>
                            Open Toolbar
                        </Button>
                        <BuyMeACoffeeButton/>
                        <form className="rzp_payment_form"></form>
                    </div>
                    <div className={"toobar-header-mobile"}>
                        <a className="navbar-brand" href="/">
                            <img className="navbar-brand-light" src="/images/brand/dark.svg" alt="Logo dark"/>
                        </a>
                        <Button variant="outline-dark" onClick={toggleShow}
                                style={{padding: '10px', width: '40px'}}
                        >
                            <FaBars/>
                        </Button>
                    </div>
                </>
            :
                null
            }


    <Offcanvas show={show} onHide={toggleShow} placement="start" onEntered = {()=> RenderRazerPayButton()}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                <a className="navbar-brand" href="/">
                    {/*<img className="navbar-brand-dark" src="/assets/img/brand/light.svg" alt="Logo light"/>*/}
                    <img className="navbar-brand-light" src="/images/brand/dark.svg" alt="Logo dark"/>
                        </a>
                    </Offcanvas.Title>
                </Offcanvas.Header>
        <Offcanvas.Body>
            <ToolbarContent
                pdfOption={pdfOption}
                setPDFOption={setPDFOption}
                toggleDragHandles={() => {
                    setShow(false);
                    toggleDragHandles();
                }}
                icon={icon}
                setIcon ={setIcon}
            />
            <BuyMeACoffeeButton/>
            <form className="rzp_payment_form"></form>
        </Offcanvas.Body>
    </Offcanvas>
        </>
    );
};

export default Toolbar;
