import React, { useState } from 'react';
import Modal from 'react-modal';
import RenderPreferences from "@/components/config/RenderPreferences";
import { populateDefaultShowPref } from "@/components/resume/core/ComponentActions";
import {Button} from "react-bootstrap";
import EditableText from "@/components/resume/core/EditableText";

const WorkExperience = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));


    const handleChange = (index, field, value, subIndex = null) => {
        const newData = [...data]; // Create a shallow copy of the data array

        if (subIndex !== null) {
            const subData = newData[index][field];
            subData[subIndex]= value;
            newData[index] = { ...newData[index], [field]:[...subData] }
        } else {
            newData[index] = { ...newData[index], [field]: value };
        }

        onUpdate(newData); // Call the update function with the modified data
    };

    const renderTimeLineItem = ({job, index}) =>{
            if(prefs.filterArray && prefs.filterArray.value.length > index && !prefs.filterArray.value[index][1]){
            return ;
        }
        return(
            <div key={index} className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-content">
                    <h5 className="timeline-header">
                        <EditableText text={job.companyName || ''}
                                      onChange={(value) => handleChange(index, 'companyName', value)}/>
                        {prefs.showYear.value &&
                            <span className="timeline-year">
                                                <EditableText text={job.year || ''}
                                                              onChange={(value) => handleChange(index, 'year', value)}/>
                                            </span>
                        }
                    </h5>
                    {prefs.showLocation.value &&
                        <h6 className={"sub-header-h6"}>
                            <EditableText text={job.location || ''}
                                          onChange={(value) => handleChange(index, 'location', value)}/>
                        </h6>
                    }
                    {prefs.showJobTitle.value &&
                        <h6 className="item-title">
                            <EditableText text={job.jobTitle || ''}
                                          onChange={(value) => handleChange(index, 'jobTitle', value)}/>
                        </h6>
                    }
                    {prefs.showResponsibilities.value &&
                        <ul className="item-list">
                            {job.responsibilities.map((responsibility, idx) => (
                                <li key={idx}>
                                    <EditableText text={responsibility || ''}
                                                  onChange={(value) => handleChange(index, 'responsibilities', value, idx)}/>
                                </li>
                            ))}
                        </ul>
                    }

                    {prefs.showKeyAchievements.value &&
                        <>
                            <h6 className="sub-header-h6">
                                <EditableText text={'Key Achievements'} onChange={(value) => {
                                }}/>
                            </h6>
                            <ul className="item-list">
                                {job.keyAchievements.map((achievement, idx) => (
                                    <li key={idx}>
                                        <EditableText text={achievement || ''}
                                                      onChange={(value) => handleChange(index, 'keyAchievements', value, idx)}/>
                                    </li>
                                ))}
                            </ul>
                        </>
                    }
                    {prefs.showTechnologiesUsed.value &&
                        <div className={"rounded-container"}>
                            <h6 className="sub-header-h6">
                                <EditableText text={'Technologies Used'} onChange={(value) => {
                                }}/>
                            </h6>
                            <ul className="item-list">
                                {job.technologiesUsed.map((tech, idx) => (
                                    <li key={idx}>
                                        <EditableText text={tech || ''}
                                                      onChange={(value) => handleChange(index, 'technologiesUsed', value, idx)}/>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }

                </div>
            </div>
        );
    };


    return (
        <>
            <div className="ResumeSection work-experience-section"
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                {prefs.showHeader && prefs.showHeader.value &&
                    <h3 className={'section-header'}>{data[0].header || 'Work Experience'}</h3>}
                {data.map((job, index) => (
                    renderTimeLineItem({job, index})
                ))}
            </div>
            <Modal isOpen={isModalOpen}
                 onRequestClose={() => {
                     onConfigUpdate({...config, preferences: prefs});
                     setIsModalOpen(false);
                 }}
                 contentLabel={prefs.title}
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
                shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default WorkExperience;
