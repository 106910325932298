import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '@/utils/cropImage';
import EditableText from '../core/EditableText';
import RenderPreferences from "@/components/config/RenderPreferences";
import {populateDefaultShowPref} from "@/components/resume/core/ComponentActions";
import {Button} from "react-bootstrap";

const defaultPhoto = "https://upload.wikimedia.org/wikipedia/commons/7/70/User_icon_BLACK-01.png";

const ProfileSection = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(data.photo || defaultPhoto);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImg = await getCroppedImg(data.photo || defaultPhoto, croppedAreaPixels);
            setCroppedImage(croppedImg);
            handleChange('photo', croppedImg);
            closeModal();
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, data.photo]);

    const handlePhotoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                handleChange('photo', e.target.result);
                setCroppedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (field, value) => {
        const newData = { ...data, [field]: value };
        onUpdate(newData);
    };

    return (
        <>
            <div className={`ResumeSection profile-section`}
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                {prefs.showHeader && prefs.showHeader.value && <h2 className={'section-header'}>{data.header || 'Profile'}</h2>}
                {prefs.showPhoto.value && (
                    <div className={'profile-photo-container'}>
                        <img
                            src={croppedImage || defaultPhoto}
                            alt="Profile"
                            style={{
                                width: `${prefs.imageSize.value}px`,
                                height: `${prefs.imageSize.value}px`,
                                borderRadius: `${prefs.borderRadius.value}%`,
                                border: prefs.borderEnabled.value ? `2px solid var(--var-color)` : 'none'
                            }}
                        />
                    </div>
                )}
                {prefs.showName.value && <h1><EditableText text={data.name || 'Your Name'} onChange={(value) => handleChange('name', value)}/></h1>}
                {prefs.showTitle.value && (
                    <h4>
                        <EditableText text={data.title || ''} onChange={(value) => handleChange('title', value)}/>
                    </h4>
                )}
                {prefs.showBio.value && (
                    <p>
                        <EditableText text={data.bio || ''} onChange={(value) => handleChange('bio', value)}/>
                    </p>
                )}
                {prefs.showPortfolio.value && (
                    <h6>
                        <EditableText text={data.portfolio || ''} onChange={(value) => handleChange('portfolio', value)}/>
                    </h6>
                )}
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}
                contentLabel={prefs.title}
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
                shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <input type="file" accept="image/*" onChange={handlePhotoUpload} />
                <div style={{ position: 'relative', width: '100%', height: 400 }}>
                    <Cropper
                        image={data.photo || defaultPhoto}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
                <Button onClick={showCroppedImage}> Crop Image </Button>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default ProfileSection;
