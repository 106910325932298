'use client';

import React, { useState, useEffect } from 'react';
import styles from '@/styles/config/RenderPreferences.module.scss';
import IconPicker from 'react-icons-picker';

const RenderPreferences = ({ compConf, updateComponent, activeCompId }) => {
    const [preferences, setPreferences] = useState(compConf.preferences || {});

    useEffect(() => {
        if (compConf.preferences) {
            setPreferences(compConf.preferences);
        }
    }, [compConf.preferences]);

    useEffect(() => {
        if (preferences) {
            updateComponent(compConf.id, preferences);
        }
    }, [preferences]);

    const handleChange = (key, value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [key]: {
                ...prevPreferences[key],
                value: value
            }
        }));
    };

    const stopClickPropagation = (e) => {
        e.stopPropagation();
    };

    const applyWebComponent = (pref, key) => {
        if (pref.visible) {
            let inputElement = null;
            switch (pref.type) {
                case 'checkbox':
                    inputElement = (
                        <input
                            type="checkbox"
                            checked={pref.value}
                            disabled={!pref.enabled}
                            onChange={(e) => handleChange(key, e.target.checked)}
                        />
                    );
                    break;
                case 'slider':
                    inputElement = (
                        <input
                            type="range"
                            min={pref['range-min']}
                            max={pref['range-max']}
                            value={pref.value}
                            disabled={!pref.enabled}
                            onChange={(e) => handleChange(key, e.target.value)}
                        />
                    );
                    break;
                case 'color':
                    inputElement = (
                        <input
                            type="color"
                            value={pref.value}
                            disabled={!pref.enabled}
                            onChange={(e) => handleChange(key, e.target.value)}
                        />
                    );
                    break;
                case 'icon':
                    inputElement = (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconPicker
                                onChange={(newV) => {
                                    handleChange(key, newV)
                                }}
                                hideSearchBar={false}
                                hideLibraryDropdown={true}
                                disable={!pref.enabled}
                                value={pref.value}
                                modalFadeStyle={{
                                    display: 'flex',
                                    inset: '0px',
                                    position: 'fixed'
                                }}
                            />
                            <span>{pref.value}</span>
                        </div>
                    );
                    break;
                case 'dropdown':
                    inputElement = (
                        <select
                            value={pref.value}
                            disabled={!pref.enabled}
                            onChange={(e) => handleChange(key, e.target.value)}
                        >
                            {pref.options.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    );
                    break;
                case "filterArray":
                    inputElement = (
                        <div className={"filterArray"}>
                            {
                                Array.isArray(pref.value) && pref.value.length >= 1 ? (
                                    pref.value.map((elem, index) => (
                                        <div key={index}>
                                            <input
                                                type="checkbox"
                                                checked={elem[1]}
                                                disabled={!pref.enabled}
                                                onChange={(e) => {
                                                    const updatedValues = [...pref.value];
                                                    updatedValues[index] = [elem[0], e.target.checked];
                                                    handleChange(key, updatedValues);
                                                }}
                                            />
                                            <label>{elem[0]}</label>
                                        </div>
                                    ))
                                ) : null
                            }
                        </div>

                    );
                default:
                    break;
            }
            return (
                <div key={key} className={styles.preferenceItem}>
                    <label><b>{pref.displayName}</b></label>
                    {inputElement}
                </div>
            );
        }
    }


    return (
        <div className={`${activeCompId === compConf.id ? styles.active : styles.inactive}`}
             onClick={stopClickPropagation}>
            {
                preferences &&
                Object.keys(preferences).map((key) => {
                    const pref = preferences[key];
                    return applyWebComponent(pref, key);
                })
            }
        </div>
    );
};

export default RenderPreferences;
