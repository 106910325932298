import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import RenderPreferences from "@/components/config/RenderPreferences";
import SortableList from "@/components/resume/core/SortableList";
import {arrayMoveImmutable as arrayMove} from "array-move";
import {
    populateDefaultShowPref,
    transformDataToCompState
} from "@/components/resume/core/ComponentActions";
import {Button} from "react-bootstrap";


const ContactDetails = ({data, onUpdate, config, onConfigUpdate}) => {
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contactItems, setContactItems] = useState(transformDataToCompState(data));

    useEffect(() => {
        setContactItems((currentStateData) => {
            return currentStateData.map(({ type, value }) => {
                return {
                    type, // Keep the type unchanged
                    value: data[type] !== undefined ? data[type] : value // Update the value if it exists in data, otherwise keep the old value
                };
            });
        });
        setPrefs(config.preferences);
    }, [config, data]);

    return (
        <>
            <div
                className={`ResumeSection`}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                }}
            >
                {prefs.showHeader && prefs.showHeader.value && <h3 className={'section-header'}>{data.header || 'Contact'}</h3>}
                <SortableList items={contactItems} prefs={prefs} useDragHandle={true} helperClass="sortableHelper"
                              handleChange={(type, value) => {
                                  onUpdate({...data, [type]: value})
                              }}
                              onSortEnd={({
                                              oldIndex,
                                              newIndex
                                          }) => setContactItems(arrayMove(contactItems, oldIndex, newIndex))}/>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}
                contentLabel={prefs.title}
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
                shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default ContactDetails;
