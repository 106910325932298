'use client';
import React, {useState, useEffect, forwardRef, useRef} from 'react';
import Career from '@/components/resume/default/Career';
import WorkExperience from '@/components/resume/default/WorkExperience';
import Education from '@/components/resume/default/Education';
import Certifications from '@/components/resume/default/Certifications';
import Skills from '@/components/resume/default/Skills';
import ProfileSection from "@/components/resume/default/ProfileSection";
import ContactDetails from "@/components/resume/default/ContactDetails";
import "@/styles/resume/templates/Primo/ResumeTemplatePrimo.scss"
import Spacer from "@/components/resume/default/Spacer";
import PersonalInfo from "@/components/resume/default/PersonalInfo";

const componentMap = {
    profile: ProfileSection,
    contact: ContactDetails,
    career: Career,
    workExperience: WorkExperience,
    education: Education,
    certifications: Certifications,
    skills: Skills,
    personalInfo: PersonalInfo,
    spacer: Spacer
};

const ResumeTemplatePrimo = forwardRef(({ formData, updateFormData, pdfOption, config, onConfigUpdate }, ref) => {
    const [pageStyle, setPageStyle] = useState({ width: '8.27in', height: '11.69in' });


    const handleUpdate = (key, updatedData) => {
        const newData = { ...formData, [key]: updatedData };
        updateFormData(newData);
    };
    const handleConfigUpdate = (newConf, pageIndex, colName, compIndex)=>{
        config.rows[pageIndex][colName][compIndex] = newConf;
        const newConfig = {
            columns:config.columns,
            rows: config.rows
        };
        onConfigUpdate(newConfig);
    }

    useEffect(() => {
        // Determine if the selected format is A4 or Letter
        const isA4 = pdfOption?.jsPDF?.format === 'a4';

        // Set dynamic page styles for the screen
        setPageStyle({
            width: isA4 ? '8.27in' : '8.5in',   // A4 width: 8.27in, Letter width: 8.5in
            height: isA4 ? '11.69in' : '11in'   // A4 height: 11.69in, Letter height: 11in
        });

        // Dynamically inject the print media rule
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
            @media print {
                @page {
                    size: ${isA4 ? 'A4' : 'Letter'} ${pdfOption?.jsPDF?.orientation || 'portrait'};
                    margin: 0mm;                   
                }
            }
        `;
        document.head.appendChild(styleElement);

        // Cleanup function to remove the style element on unmount or when pdfOption changes
        return () => {
            document.head.removeChild(styleElement);
        };
    }, [pdfOption]);

    const renderComponent = (compConfig,  handleConfigUpdate) => {
        if(!compConfig) return null;
        const Component = componentMap[compConfig.name];
        if (!Component) return null;
        return (
            <Component
                key={compConfig.id}
                data={formData[compConfig.name] || {}}
                onUpdate={(updatedData) => handleUpdate(compConfig.name, updatedData)}
                config={compConfig || {}}
                onConfigUpdate={handleConfigUpdate}
            />
        );
    };

    return (
        <>
            <div className={"resume-template-container"} id="printable-content" >
                {config.rows.map((page, pageIndex) => (

                        <div key={`resume-page-${pageIndex}`} className={"resume-template-page"} style={pageStyle}>
                            <div className={"resume-template"}>
                                <div className={"left-column"}>
                                    {page.left.map((compConf, index) =>
                                        renderComponent(compConf,
                                            (newConf) => {
                                                handleConfigUpdate(newConf, pageIndex, 'left', index)
                                            }
                                        ))}
                                </div>
                                <div className={"right-column"}>
                                    {page.right.map((compConf, index) =>
                                        renderComponent(compConf,
                                            (newConf) => {
                                                handleConfigUpdate(newConf, pageIndex, 'right', index)
                                            }
                                        ))}
                                </div>
                                {pageIndex > 0 && (
                                    <div key={`resume-footer-${pageIndex}`} className="resume-footer">
                                        <div className="resume-name">{formData.profile.name} &nbsp;&nbsp;|&nbsp;&nbsp; </div>
                                        <div className="resume-page-number">
                                            Page {pageIndex + 1}/{config.rows.length}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                ))}
            </div>
        </>

    );
});
ResumeTemplatePrimo.displayName = 'ResumeTemplatePrimo';

export default ResumeTemplatePrimo;
