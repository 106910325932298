import React from 'react';
import {Button} from "react-bootstrap";

const ClearLocalStorageButton = () => {
    const clearLocalStorage = () => {
        if(typeof window !== 'undefined' && typeof sessionStorage !== 'undefined'){
            sessionStorage.clear();
            // alert('Local storage cleared!');
        }
        window.location.reload();
    };

    return (
        <Button variant={"outline-danger"} onClick={clearLocalStorage}>
            Clear Local Storage
        </Button>
    );
};

export default ClearLocalStorageButton;
