import React, { useEffect, useState } from 'react';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';

export const handleFontChange = (font) => {
    document.documentElement.style.setProperty('--resume-font-family', font);
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        try {
            const globalConfig = sessionStorage.getItem('globalConfig');
            const parsedConfig = globalConfig ? JSON.parse(globalConfig) : {};
            parsedConfig['preferredFont'] = font;
            sessionStorage.setItem('globalConfig', JSON.stringify(parsedConfig));
        } catch (error) {
            console.error("Error updating globalConfig in sessionStorage:", error);
        }
    }
};

const FontSwitcher = () => {
    // Retrieve the font from sessionStorage or use default
    const globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
    const [font, setFont] = useState(globalConfig["preferredFont"]);
    const [customFont, setCustomFont] = useState("");
    const [fonts, setFonts] = useState([
            { name: "Arial", value: "Arial, sans-serif" },
            { name: "Times New Roman", value: "Times New Roman, serif" },
            { name: "Calibri", value: "Calibri, sans-serif" },
            { name: "Helvetica", value: "Helvetica, sans-serif" },
            { name: "Georgia", value: "Georgia, serif" },
            { name: "Verdana", value: "Verdana, sans-serif" },
            { name: "Garamond", value: "Garamond, serif" },
            { name: "Cambria", value: "Cambria, serif" },
            { name: "Tahoma", value: "Tahoma, sans-serif" },
            { name: "Trebuchet MS", value: "Trebuchet MS, sans-serif" }
        ]
    );


    const handleAddCustomFont = () => {
        if (customFont.trim() !== "") {
            const customFontWithFallback = `${customFont}, sans-serif`;
            setFonts([...fonts, { name: customFont, value: customFontWithFallback }]);
            setCustomFont("");
        }
    };

    return (
        <>
            <Form.Select value={font} onChange={(e)=>{setFont(e.target.value); handleFontChange(e.target.value)}} aria-label="Font Selector">
                {fonts.map((fontOption) => (
                    <option key={fontOption.value} value={fontOption.value}>
                        {fontOption.name}
                    </option>
                ))}
            </Form.Select>
            <InputGroup className="mt-3">
                <FormControl
                    placeholder="Enter custom font"
                    value={customFont}
                    onChange={(e) => setCustomFont(e.target.value)}
                />
                <Button variant="outline-secondary" onClick={handleAddCustomFont}>
                    Add Font
                </Button>
            </InputGroup>
        </>
    );
};

export default FontSwitcher;
