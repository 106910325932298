'use client';

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '@/styles/resume/templates/Primo/ResumeConfigPrimo.scss';
import allComponents from './allComponents.json';
import ConfigPageHeader from '@/components/config/ConfigPageHeader';
import { intial_page } from '@/constants';
import {
    addPage,
    deletePage,
    removeComponent,
    handlePresetChange,
    updateAvailableComponents,
    addComponent,
    updateComponent
} from '@/components/config/ConfigPageActions';
import ConfigPageColumn from "@/components/config/ConfigPageColumn";
import {Button} from "react-bootstrap";

const ResumeConfigPrimo = ({ savedConfig, onSave }) => {
    const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
    const [pages, setPages] = useState(() => {
        if (!savedConfig) {
            return intial_page;
        }
        return savedConfig;
    });
    const [availableComponents, setAvailableComponents] = useState({});
    const [allowDuplicates, setAllowDuplicates] = useState(true);
    const [activeComponentId, setActiveComponentId] = useState(null);

    const handleComponentClick = (componentId) => {
        setActiveComponentId(prevId => (prevId === componentId ? null : componentId));
    };
    useEffect(() => {
        setPages(savedConfig);
    }, [savedConfig]);

    useEffect(() => {
        if(pages === intial_page){
            handlePresetChange("preset1", setPages)
            handleConfigChanges(true);
        }
    }, []);

    useEffect(() => {
        const availCom = updateAvailableComponents(pages, allowDuplicates);
        setAvailableComponents(availCom);
    }, [allowDuplicates, pages]);

    const handleConfigChanges = (persist=false) => {
        if(persist){
            onSave(pages);
        }
        else{
            if(!savedConfig){
                setPages(intial_page);
            }
            else{
                setPages(savedConfig);
            }
        }
        setIsConfigModalOpen(false);
    };

    return (
        <>
            <Button variant="info" onClick={() => {setIsConfigModalOpen(true)}} >
                Layout Configurations
            </Button>
            <Modal isOpen={isConfigModalOpen}
                   onRequestClose={()=>handleConfigChanges(false)} contentLabel="Resume Configuration"
                   className="ReactModal__Content"
                   overlayClassName="ReactModal__Overlay"
                   shouldCloseOnOverlayClick={true}
                   scrollable
            >
                <div className={'config-container'}>
                    <ConfigPageHeader
                        handlePresetChange={(preset) => handlePresetChange(preset, setPages)}
                        allowDuplicates={allowDuplicates}
                        setAllowDuplicates={setAllowDuplicates}
                    />
                    {pages.rows.map((row, rowIndex) => (
                        <div key={rowIndex} className={'page'}>
                            <div className={'pageHeader'}>
                                <h3>Page {rowIndex + 1}</h3>
                                <Button onClick={() => deletePage(rowIndex, pages, setPages)}>Delete Page</Button>
                            </div>
                            <div className={'columns'}>
                                {pages.columns[rowIndex].map((colName) => (
                                    row[colName] && (
                                        <ConfigPageColumn
                                            key={`row-${rowIndex}-column-${colName}`}
                                            columnName={colName}
                                            columnData={pages.rows[rowIndex][colName]}
                                            availableComponents={availableComponents}
                                            addComponent={(comp) => {
                                                addComponent(comp, rowIndex, colName, pages, setPages, allComponents)
                                            }}
                                            removeComponent={(index) => removeComponent(rowIndex, colName, index, pages, setPages)}
                                            updateComponent={(id, prefs) => updateComponent(id, prefs, pages, setPages)}
                                            activeComponentId={activeComponentId}
                                            handleComponentClick={handleComponentClick}
                                        />
                                    )
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className={'actions'}>
                        <Button onClick={() => addPage(pages, setPages)}>Add Page</Button>
                        <Button onClick={()=>handleConfigChanges(true)}>Save Configuration</Button>
                        <Button variant="outline-info" onClick={()=>handleConfigChanges(false)}>Close</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ResumeConfigPrimo;
