import React, { useState } from 'react';
import Modal from 'react-modal';
import RenderPreferences from "@/components/config/RenderPreferences";
import { populateDefaultShowPref } from "@/components/resume/core/ComponentActions";
import {Button} from "react-bootstrap";
import EditableText from "@/components/resume/core/EditableText";

const Education = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));


    const handleChange = (index, field, value, subIndex = null) => {
        const newData = [...data]; // Create a shallow copy of the data array

        if (subIndex !== null) {
            const subData = newData[index][field];
            subData[subIndex]= value;
            newData[index] = { ...newData[index], [field]:[...subData] }
        } else {
            newData[index] = { ...newData[index], [field]: value };
        }

        onUpdate(newData); // Call the update function with the modified data
    };

    const renderTimeLineItem = ({itemData, index}) =>{
        if(prefs.filterArray && prefs.filterArray.value.length > index && !prefs.filterArray.value[index][1]){
            return ;
        }
        return(
            <div key={index} className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-content">
                    <h5 className="timeline-header">
                        <EditableText text={itemData.degree || ''}
                                      onChange={(value) => handleChange(index, 'degree', value)}/>
                        {prefs.showGraduationYear.value &&
                            <span className="timeline-year">
                                                <EditableText text={itemData.graduationYear || ''}
                                                              onChange={(value) => handleChange(index, 'graduationYear', value)}/>
                                            </span>
                        }
                    </h5>
                    <span className="timeline-sub-header">
                        {prefs.showLocation.value &&
                            <span>
                            <EditableText text={itemData.location || ''}
                                          onChange={(value) => handleChange(index, 'location', value)}/>
                        </span>
                        }
                        <span></span>
                        {prefs.showGpa.value &&
                            <span>
                            GPA : <b> <EditableText text={itemData.gpa || ''} onChange={(value) => handleChange(index, 'gpa', value)}/> </b>

                        </span>
                        }
                    </span>
                        {prefs.showRelevantCoursework.value &&
                            <div className={"rounded-container"}>
                                <h6 className="sub-header-h6">
                                    <EditableText text={'Relevant Course Work'} onChange={(value) => {
                                    }}/>
                                </h6>
                                <ul className="item-list">
                                    {itemData.relevantCoursework.map((tech, idx) => (
                                        <li key={idx}>
                                            <EditableText text={tech || ''}
                                                          onChange={(value) => handleChange(index, 'relevantCoursework', value, idx)}/>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                </div>
            </div>
    );
    };


    return (
        <>
            <div className="ResumeSection education-section"
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                {prefs.showHeader && prefs.showHeader.value &&
                    <h3 className={'section-header'}>{data[0].header || 'Education'}</h3>}
                {data.map((itemData, index) => (
                    renderTimeLineItem({itemData, index})
                ))}
            </div>
            <Modal isOpen={isModalOpen}
                 onRequestClose={() => {
                     onConfigUpdate({...config, preferences: prefs});
                     setIsModalOpen(false);
                 }}
                 contentLabel={prefs.title}
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
                shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default Education;
