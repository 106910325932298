import React, { useState, useEffect } from 'react';
import EditableText from '../core/EditableText';
import {populateDefaultShowPref} from "@/components/resume/core/ComponentActions";
import Modal from "react-modal";
import RenderPreferences from "@/components/config/RenderPreferences";
import {Button} from "react-bootstrap";
import { IconPickerItem } from 'react-icons-picker'

const Career = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));

    const handleChange = (field, value, index=null) => {
        let newData;
        if(index!==null){
            const subData = data[field];
            subData[index]= value;
            newData = { ...data, [field]: subData };
        }
        else{
            newData = { ...data, [field]: value };
        }
        onUpdate(newData); // Call the update function with the modified data
    };


    return (
        <>
            <div className="ResumeSection career-section"
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                {prefs.showHeader && prefs.showHeader.value &&
                    <h3 className={'section-header'}>{data.header || 'Career'}</h3>}

                {prefs.showProfession.value &&
                    <h5><EditableText text={data.profession || 'Profession'} onChange={(value) => handleChange('profession', value)}/></h5>}
                {prefs.showObjective.value && (
                    <h6>
                        <EditableText text={data.objective || ''} onChange={(value) => handleChange('objective', value)}/>
                    </h6>
                )}
                {prefs.showSummary.value &&
                    <ul className="summary-list">
                        {data.summary.map((summary_item, idx) => (
                            <li key={idx}>
                                { prefs.icon && prefs.icon.value && <><IconPickerItem value={prefs.icon.value} /> <span> </span></>}
                                <EditableText text={summary_item || ''}
                                              onChange={(value) => handleChange('summary', value, idx)}/>
                            </li>
                        ))}
                    </ul>
                }
            </div>
            <Modal isOpen={isModalOpen}
                   onRequestClose={() => {
                       onConfigUpdate({...config, preferences: prefs});
                       setIsModalOpen(false);
                   }}
                   contentLabel={prefs.title}
                   className="ReactModal__Content"
                   overlayClassName="ReactModal__Overlay"
                   shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default Career;
