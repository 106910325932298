import { CURRENT_VERSION } from '@/constants';

const VersionCheck = () => {
    if (typeof sessionStorage === 'undefined') {
        return;
    }

    const storedVersion = sessionStorage.getItem('app_version');
    const parseVersion = (version) => {
        const [major, minor] = version.split('.').map(Number);
        return { major, minor };
    };

    const { major: storedMajor, minor: storedMinor } = parseVersion(storedVersion || '0.0.0');
    const { major: currentMajor, minor: currentMinor } = parseVersion(CURRENT_VERSION);

    if (storedMajor !== currentMajor || storedMinor !== currentMinor) {
        console.log('Major or minor version change detected. Clearing local storage.');
        sessionStorage.clear();
        sessionStorage.setItem('app_version', CURRENT_VERSION);
        window.location.reload();
    }
};

export default VersionCheck;
