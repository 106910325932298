import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ReactJson from 'react-json-view';
import {Button} from "react-bootstrap";

const JsonEditorModal = ({ isJsonEditorOpen, setIsJsonEditorOpen, masterData, setMasterData }) => {
    const [data, setData] = useState(masterData);

    useEffect(() => {
        setData(masterData);
    }, [masterData]);

    const handleUpdate = (updatedData) => {
        setData(updatedData);
        setMasterData(updatedData);
    };

    return (
        <Modal
            isOpen={isJsonEditorOpen}
            onRequestClose={() => setIsJsonEditorOpen(false)}
            contentLabel="Edit JSON Data"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '80%',
                },
            }}
        >
            <h2>Edit JSON Data</h2>
            <ReactJson
                src={data}
                onEdit={(edit) => handleUpdate(edit.updated_src)}
                onAdd={(add) => handleUpdate(add.updated_src)}
                onDelete={(del) => handleUpdate(del.updated_src)}
                inputRef={null}
            />
            <Button onClick={() => setIsJsonEditorOpen(false)}>Close</Button>
        </Modal>
    );
};

export default JsonEditorModal;
