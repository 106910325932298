import React from 'react';
import ResumeTemplate from '@/components/resume/ResumeTemplate';
import "@/styles/resume_mgmt.scss"

const ResumeContainer = ({ jsonData, pdfOption, openJsonEditor, setJsonData }) => {
    const pdfOptions = {
        A4: {
            margin: 0,
            filename: 'resume_a4.pdf',
            x: 10,
            y: 10,
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        },
        Letter: {
            margin: 0,
            filename: 'resume_letter.pdf',
            x: 10,
            y: 10,
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' }
        }
    };


    return (
        <div className={'resume-container'}>
            <ResumeTemplate
                formData={jsonData}
                onEdit={setJsonData}
                openJsonEditor={openJsonEditor}
                template='Primo'
                pdfOption={pdfOptions[pdfOption]}
            />
        </div>
    );
};

export default ResumeContainer;
