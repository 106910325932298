'use client';

import React, { useRef, useEffect, useState } from 'react';
import Modal from "react-modal";
import {Alert, Button, ButtonGroup} from "react-bootstrap";
import {handlePrimaryChange, handleSecondaryChange} from "@/components/config/ThemeColorPicker";
import {handleFontChange} from "@/components/config/FontSwitcher";

const ResumeTemplate = ({ formData, onEdit, template, pdfOption, openJsonEditor }) => {
    const [message, setMessage] = useState(""); // For alert messages
    const [configuration, setConfiguration] = useState(() => {
        const defConf = { [template]: false };
        if(typeof window !== 'undefined' && typeof sessionStorage !== 'undefined'){
            let savedConfig = sessionStorage.getItem('resumeConfiguration');
            if (savedConfig) {
                savedConfig = JSON.parse(savedConfig);
            } else {
                sessionStorage.setItem('resumeConfiguration', JSON.stringify(defConf));
                savedConfig = defConf;
            }
            return savedConfig;
        }
        else{
           return defConf;
        }
    });

    const ResumeTemplateComponent = require(`@/components/resume/templates/${template}/ResumeTemplate${template}`).default;
    const ResumeConfigComponent = require(`@/components/resume/templates/${template}/ResumeConfig${template}`).default;
    const ref = useRef();

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
            let globalConfig = sessionStorage.getItem('globalConfig');
            if (globalConfig) {
                try {
                    const parsedConfig = JSON.parse(globalConfig);
                    if (parsedConfig && parsedConfig['themeColorPrimary']) {
                        handlePrimaryChange(parsedConfig['themeColorPrimary']);
                    }
                    if (parsedConfig && parsedConfig['themeColorSecondary']) {
                        handleSecondaryChange(parsedConfig['themeColorSecondary']);
                    }
                    if(parsedConfig && parsedConfig['preferredFont']){
                        handleFontChange(parsedConfig['preferredFont']);
                    }
                } catch (error) {
                    console.error("Error parsing globalConfig from sessionStorage:", error);
                }
            }
            else{
                globalConfig = {
                    themeColorPrimary: "#363f4e",
                    themeColorSecondary: "#f4f7f7",
                    preferredFont: "Georgia, Times, 'Times New Roman', serif"
                }
                sessionStorage.setItem('globalConfig', JSON.stringify(globalConfig));
            }
        }
    }, []);
    useEffect(() => {
        if(typeof window !== 'undefined' && typeof sessionStorage !== 'undefined'){
            sessionStorage.setItem('resumeConfiguration', JSON.stringify(configuration));
        }
    }, [configuration]);
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(""); // Clear the message
            }, 2000); // 3 seconds
            return () => clearTimeout(timer); // Cleanup timer if component unmounts
        }
    }, [message]);

    const saveConfiguration = (config) => {
        setConfiguration((prevConfig) => ({ ...prevConfig, [template]: config }));
    };
    const handleDownload = () => {
        const globalConfig = sessionStorage.getItem('globalConfig');
        const parsedGlobalConfig = globalConfig ? JSON.parse(globalConfig) : {};
        const dataToDownload = {
            "ResumeData": formData,
            "ResumeConfiguration": configuration,
            "GlobalConfig": parsedGlobalConfig
        };
        const jsonData = JSON.stringify(dataToDownload, null, 2);
        const blob = new Blob([jsonData], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "resume_data.json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setMessage("Resume data downloaded successfully!"); // Set success message
    };
    // Upload function for resume data and configuration
    const handleUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const uploadedData = JSON.parse(event.target.result);
                onEdit(uploadedData.ResumeData);
                setConfiguration(uploadedData.ResumeConfiguration);
                document.documentElement.style.setProperty('--resume-font-family', uploadedData.GlobalConfig["preferredFont"]);
                document.documentElement.style.setProperty('--resume-primary-color', uploadedData.GlobalConfig["themeColorPrimary"]);
                document.documentElement.style.setProperty('--resume-secondary-color', uploadedData.GlobalConfig["themeColorSecondary"]);
                sessionStorage.setItem("globalConfig", JSON.stringify(uploadedData.GlobalConfig));
                setMessage("Resume data uploaded successfully!");
            };
            reader.readAsText(file);
            event.target.value = null;
        }
        // window.location.reload();
    };

    return (
        <>
            {message && <Alert variant="success">{message}</Alert>}
            <div className={"conf-header"}>
                <ResumeConfigComponent
                    savedConfig={configuration[template]}
                    onSave={saveConfiguration}
                />
                <ButtonGroup>
                    <Button variant="outline-dark" onClick={() => document.getElementById('upload-file').click()}>Upload
                        Data</Button>
                    <Button variant="outline-dark" onClick={openJsonEditor}>Edit Data</Button>
                    <Button variant="outline-dark" onClick={() => {
                        handleDownload()
                    }}>Download Data</Button>
                    <Button variant="outline-success" onClick={() => {
                        window.print()
                    }}>Download PDF Resume</Button>
                </ButtonGroup>
            </div>

            {configuration[template] && (
                <>
                    <ResumeTemplateComponent
                        ref={ref}
                        formData={formData}
                        updateFormData={onEdit}
                        pdfOption={pdfOption}
                        config={configuration[template]}
                        onConfigUpdate={(newConf) => {
                            saveConfiguration(newConf)
                        }}
                    />

                </>
            )}
            <input
                type="file"
                accept="application/json"
                style={{display: "none"}}
                id="upload-file"
                onChange={handleUpload}
            />
        </>
    );
};

export default ResumeTemplate;
