import React, { useState } from 'react';

const styles = {
    default: {
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease, boxShadow 0.2s ease',
        height: "40px",
        margin: "3px 2px 0 3px"
    },
    hover: {
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.15)',
    },
};

const BuyMeACoffeeButton = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    return (
        <div>
            <a
                href="https://buymeacoffee.com/dev4u"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }} // Optional for no underline
            >
                <img
                    src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=dev4u&button_colour=FFDD00&font_colour=000000&font_family=Lato&outline_colour=000000&coffee_colour=ffffff"
                    alt="Buy Me A Coffee"
                    style={isHovered ? { ...styles.default, ...styles.hover } : styles.default} // Apply hover styles if isHovered is true
                    onMouseOver={handleMouseOver} // On hover
                    onMouseOut={handleMouseOut}   // When hover ends
                />
            </a>
        </div>
    );
};

export default BuyMeACoffeeButton;
