import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import RenderPreferences from '@/components/config/RenderPreferences';
import { Button } from 'react-bootstrap';
import {
    populateDefaultShowPref,
    transformDataToCompState,
} from '@/components/resume/core/ComponentActions';
import {IconPickerItem} from "react-icons-picker";
import EditableText from "@/components/resume/core/EditableText";

const PersonalInfo = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChange = (field, value, index=null) => {
        let newData;
        if(index!==null){
            const subData = data.tabularRecords;
            subData[index][field]= value;
            newData = { ...data, tabularRecords: subData };
        }
        else{
            newData = { ...data, [field]: value };
        }
        onUpdate(newData); // Call the update function with the modified data
    };

    return (
        <>
            <div
                className="ResumeSection personalinfo-section"
                onMouseDown={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                }}
            >
                {prefs.showHeader && prefs.showHeader.value && (
                    <h3 className="section-header">
                        <EditableText text={data.header || 'Personal Info'}
                                      onChange={(value) => handleChange('header', value)}/>
                    </h3>
                )}
                <style jsx>{`
                    .label-column {
                        text-align: right;
                        padding-right: 10px;
                    }

                    .value-column {
                        text-align: left;
                        padding-left: 10px;
                    }
                `}</style>
                <div className="personal-details">
                    <table>
                        <tbody>
                        {data.tabularRecords.map(
                            (record, index) =>
                                // field.pref && field.pref.value && (
                                    <tr key={index}>
                                        <td className="label-column">
                                            {record.icon &&
                                                <>
                                                    <IconPickerItem value={record.icon}/>
                                                    <span> </span>
                                                </>
                                            }
                                            <strong><EditableText text={record.label || ''}
                                                                  onChange={(value) => handleChange('label', value, index)}/></strong>
                                        </td>
                                        <td className="value-column"><EditableText text={record.value || ''}
                                                                                   onChange={(value) => handleChange('value', value, index)}/></td>
                                    </tr>
                                // )
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}
                contentLabel={prefs.title}
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
                shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button
                    onClick={() => {
                        onConfigUpdate({ ...config, preferences: prefs });
                        setIsModalOpen(false);
                    }}
                >
                    Close
                </Button>
            </Modal>
        </>
    );
};

export default PersonalInfo;
