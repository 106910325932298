'use client';

import React, { useState } from 'react';
import styles from '@/styles/config/ConfigPageColumn.module.scss';
import RenderPreferences from "@/components/config/RenderPreferences";
import {Button} from "react-bootstrap";

const ConfigPageColumn = ({ columnName, columnData, availableComponents, addComponent, removeComponent, updateComponent, activeComponentId, handleComponentClick }) => {

    const [selectedValue, setSelectedValue] = useState("");

    const handleChange = (e) => {
        const value = e.target.value;
        if (value) {
            addComponent(value);
            setSelectedValue(""); // Reset the select value to default
        }
    };

    return (
        <div className={styles.column}>
            <h4>{columnName} column</h4>
            <select value={selectedValue} onChange={handleChange}>
                <option value="">Select a component</option>
                {Object.keys(availableComponents).map((key) => (
                    <option key={key} value={key}>{availableComponents[key]}</option>
                ))}
            </select>
            <ul>
                {columnData.map((component, i) => (
                    <li
                        key={component.id}
                        onClick={() => handleComponentClick(component.id)}
                        // onMouseLeave={() => {setActiveComponentId(null)}}
                    >
                        <span>{component.displayName}</span>
                        <RenderPreferences compConf={component} updateComponent={updateComponent} activeCompId={activeComponentId} />
                        <Button variant={"outline-danger"} onClick={(e) => { e.stopPropagation(); removeComponent(i); }}>Remove</Button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ConfigPageColumn;
