import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';


export const handlePrimaryChange = (newColor) => {
    document.documentElement.style.setProperty('--resume-primary-color', newColor);
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        try {
            const globalConfig = sessionStorage.getItem('globalConfig');
            const parsedConfig = globalConfig ? JSON.parse(globalConfig) : {};
            parsedConfig['themeColorPrimary'] = newColor;
            sessionStorage.setItem('globalConfig', JSON.stringify(parsedConfig));
        } catch (error) {
            console.error("Error updating globalConfig in sessionStorage:", error);
        }
    }
};

export const handleSecondaryChange = (newColor) => {
    document.documentElement.style.setProperty('--resume-secondary-color', newColor);
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        try {
            const globalConfig = sessionStorage.getItem('globalConfig');
            const parsedConfig = globalConfig ? JSON.parse(globalConfig) : {};
            parsedConfig['themeColorSecondary'] = newColor;
            sessionStorage.setItem('globalConfig', JSON.stringify(parsedConfig));
        } catch (error) {
            console.error("Error updating globalConfig in sessionStorage:", error);
        }
    }
};

const ThemeColorPicker = () => {
    const globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
    const [primaryColor, setPrimaryColor] = useState(globalConfig["themeColorPrimary"]);
    const [secondaryColor, setSecondaryColor] = useState(globalConfig["themeColorSecondary"]);

    return (
        <div
            style={{padding: '20px', maxWidth: '400px', margin: 'auto', border: '1px solid #ccc', borderRadius: '8px'}}>
            <h3 style={{textAlign: 'center', marginBottom: '20px'}}>Theme Color</h3>
            <table style={{width: '100%', borderCollapse: 'collapse'}}>
                <tbody>
                <tr>
                    <td style={{padding: '5px', fontWeight: 'bold'}}>Primary Color:</td>
                    <td style={{padding: '5px'}}>

                        <input type="color" value={primaryColor} onChange={(e)=>{setPrimaryColor(e.target.value); handlePrimaryChange(e.target.value)}}/>
                    </td>
                </tr>
                <tr>
                    <td style={{padding: '5px', fontWeight: 'bold'}}>Secondary Color:</td>
                    <td style={{padding: '5px'}}>
                        <input type="color" value={secondaryColor} onChange={(e)=> {setSecondaryColor(e.target.value); handleSecondaryChange(e.target.value)}}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    );
};

export default ThemeColorPicker;
