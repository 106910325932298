import React, { useState } from 'react';
import Modal from 'react-modal';
import RenderPreferences from "@/components/config/RenderPreferences";
import { populateDefaultShowPref } from "@/components/resume/core/ComponentActions";
import {Button} from "react-bootstrap";
import EditableText from "@/components/resume/core/EditableText";
import { IconPickerItem } from 'react-icons-picker'

const Skills = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));


    const handleChange = (index, field, value, subIndex = null) => {
        const newData = [...data]; // Create a shallow copy of the data array

        if (subIndex !== null) {
            const subData = newData[index][field];
            subData[subIndex]["name"] = value;
            newData[index] = { ...newData[index], [field]:[...subData] }
        } else {
            newData[index] = { ...newData[index], [field]: value };
        }

        onUpdate(newData); // Call the update function with the modified data
    };

    const renderItem = ({itemData, index}) =>{
        if(prefs.filterArray && prefs.filterArray.value.length > index && !prefs.filterArray.value[index][1]){
            return ;
        }
        return (
            <div key={index}  className="skills-container">
                <h5 className={"section-header"}><EditableText text={itemData.title || ''} onChange={(value) =>
                    handleChange(index, 'title', value)}/></h5>
                <ul>
                    {itemData.skillList.map((itemData, idx) => (
                        <li key={idx} className="skill">
                            {prefs.icon && prefs.icon.value &&
                                <IconPickerItem value={prefs.icon.value} />
                            }
                            <span className="skill-name">
                            <EditableText text={itemData.name || ''} onChange={(value) =>
                                handleChange(index, 'skillList', value, idx)}/>
                        </span>
                            {
                                prefs.showLevel.value &&
                                <div className="skill-bar" style={{width: (itemData.level / 5) * 100 + 'px'}}></div>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        );
    };


    return (
        <>
            <div className="ResumeSection skills-section"
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                {prefs.showHeader && prefs.showHeader.value &&
                    <h3 className={'section-header'}>{data[0].header || 'Skills'}</h3>}
                {data.map((itemData, index) => (
                    renderItem({itemData, index})
                ))}
            </div>
            <Modal isOpen={isModalOpen}
                   onRequestClose={() => {
                       onConfigUpdate({...config, preferences: prefs});
                       setIsModalOpen(false);
                   }}
                   contentLabel={prefs.title}
                   className="ReactModal__Content"
                   overlayClassName="ReactModal__Overlay"
                   shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default Skills;
