import React, {useState} from 'react';
import EditableText from '../core/EditableText';
import {populateDefaultShowPref} from "@/components/resume/core/ComponentActions";
import Modal from "react-modal";
import RenderPreferences from "@/components/config/RenderPreferences";
import {Button} from "react-bootstrap";

const Certifications = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));

    const handleChange = (index, field, value, subIndex = null) => {
        const newData = [...data]; // Create a shallow copy of the data array

        if (subIndex !== null) {
            const subData = newData[index][field];
            subData[subIndex]= value;
            newData[index] = { ...newData[index], [field]:[...subData] }
        } else {
            newData[index] = { ...newData[index], [field]: value };
        }

        onUpdate(newData); // Call the update function with the modified data
    };

    const renderTimeLineItem = ({cert, index}) =>{
        if(prefs.filterArray && prefs.filterArray.value.length > 0 && !prefs.filterArray.value[index][1]){
            return ;
        }
        return(
            <div key={index} className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-content">
                    <h5 className="timeline-header">
                        <EditableText text={cert.name || ''}
                                      onChange={(value) => handleChange(index, 'name', value)}/>
                        {prefs.showDateObtained.value &&
                            <span className="timeline-year">
                                <EditableText text={cert.dateObtained || ''} onChange={(value) => handleChange(index, 'dateObtained', value)}/>
                                -
                                <EditableText text={cert.expirationDate || ''} onChange={(value) => handleChange(index, 'expirationDate', value)}/>
                            </span>
                        }
                    </h5>

                    {prefs.showIssuingOrganization.value &&
                        <span className={"sub-header-h6"}>
                            <EditableText text={cert.issuingOrganization || ''}
                                          onChange={(value) => handleChange(index, 'issuingOrganization', value)}/>
                        </span>
                    }
                </div>
            </div>
        );
    };


    return (
        <>
            <div className="ResumeSection certification-section"
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                {prefs.showHeader && prefs.showHeader.value &&
                    <h3 className={'section-header'}>{data[0].header || 'Work Experience'}</h3>}
                {data.map((cert, index) => (
                    renderTimeLineItem({cert, index})
                ))}
            </div>
            <Modal isOpen={isModalOpen}
                 onRequestClose={() => {
                     onConfigUpdate({...config, preferences: prefs});
                     setIsModalOpen(false);
                 }}
                 contentLabel={prefs.title}
                className="ReactModal__Content"
                overlayClassName="ReactModal__Overlay"
                shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default Certifications;
