import React from 'react';
import { Form } from 'react-bootstrap';

const PDFOptionsSwitcher = ({ currentOption, onChangeOption }) => {
    const pdfOptions = ["A4", "Letter"];

    const handleChange = (e) => {
        const selectedOption = pdfOptions[e.target.value];
        onChangeOption(selectedOption);
    };

    return (
        <>
            <Form.Label>Pdf Size:</Form.Label>
            <Form.Select
                value={pdfOptions.indexOf(currentOption)}
                onChange={handleChange}
                aria-label="PDF Options"
            >
                {pdfOptions.map((option, index) => (
                    <option key={index} value={index}>
                        {option}
                    </option>
                ))}
            </Form.Select>
        </>
    );
};

export default PDFOptionsSwitcher;
