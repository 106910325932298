import React, { useState, useEffect } from 'react';
import EditableText from '../core/EditableText';
import {populateDefaultShowPref} from "@/components/resume/core/ComponentActions";
import Modal from "react-modal";
import RenderPreferences from "@/components/config/RenderPreferences";
import {Button} from "react-bootstrap";


const Spacer = ({ data, onUpdate, config, onConfigUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prefs, setPrefs] = useState(populateDefaultShowPref(data, config.preferences));

    const handleChange = (field, value, index=null) => {
        let newData;
        if(index!==null){
            const subData = data[field];
            subData[index]= value;
            newData = { ...data, [field]: subData };
        }
        else{
            newData = { ...data, [field]: value };
        }
        onUpdate(newData); // Call the update function with the modified data
    };
    const getCss = () => {
        return {
            background: prefs.backgroundColor ? prefs.backgroundColor.value : "#FFF",
            height: prefs.spaceHeight ? prefs.spaceHeight.value+"px" : "10px"
        };
    };


    return (
        <>
            <div className="ResumeSection spacer-section"
                 onMouseDown={(e) => {
                     e.stopPropagation();
                     setIsModalOpen(true);
                 }}
            >
                <div style={getCss()}> </div>
            </div>
            <Modal isOpen={isModalOpen}
                   onRequestClose={() => {
                       onConfigUpdate({...config, preferences: prefs});
                       setIsModalOpen(false);
                   }}
                   contentLabel={prefs.title}
                   className="ReactModal__Content"
                   overlayClassName="ReactModal__Overlay"
                   shouldCloseOnOverlayClick={true}
            >
                <h2>{prefs.title}</h2>
                <RenderPreferences
                    compConf={config}
                    updateComponent={(id, newPref) => {
                        setPrefs(newPref);
                    }}
                    activeCompId={config.id}
                />
                <Button onClick={() => {
                    onConfigUpdate({...config, preferences: prefs});
                    setIsModalOpen(false);
                }}>Close
                </Button>
            </Modal>
        </>
    );
};

export default Spacer;
