import React from 'react';
import styles from '@/styles/config/ConfigPageHeader.module.scss';
import presets from '@/data/presets_dual_column_page.json';

const ConfigPageHeader = ({ handlePresetChange, allowDuplicates, setAllowDuplicates }) => (
    <div className={styles.header}>
        <div className={styles.presetSelector}>
            <label htmlFor="preset">Load Preset Configuration:</label>
            <select id="preset" onChange={(e) => handlePresetChange(e.target.value)}>
                <option value="">Select a preset</option>
                {Object.keys(presets).map((key) => (
                    <option key={key} value={key}>{key}</option>
                ))}
            </select>
        </div>
        <div className={styles.duplicateCheckbox}>
            <label>
                <input
                    type="checkbox"
                    checked={allowDuplicates}
                    onChange={(e) => setAllowDuplicates(e.target.checked)}
                />
                Allow Duplicate Components
            </label>
        </div>
    </div>
);

export default ConfigPageHeader;
