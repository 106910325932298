

export const transformDataToCompState = (data, excludedKeys=['header']) => {
    return Object.keys(data)
        .filter(key => !excludedKeys.includes(key)) // Exclude any key that is in the excludedKeys array
        .map(key => ({ type: key, value: data[key] }));
};

const defaultPrefTemplate = (key) => ({
    type: "checkbox",
    value: true,
    enabled: true,
    visible: true,
    displayName: `Show ${key.charAt(0).toUpperCase() + key.slice(1)}` // Capitalize first letter of key
});

const addPrefKey = (prefs, prefKey, key) => {
    if(prefs && !prefs.hasOwnProperty(prefKey)) {
        prefs[prefKey] = defaultPrefTemplate(key);
    }
}

const mapPrefProperty = (data, prefs )  =>{
    Object.keys(data).forEach(key => {
        if (!['indexTitle'].includes(key)) {
            const prefKey = `show${key.charAt(0).toUpperCase() + key.slice(1)}`;
            addPrefKey(prefs, prefKey, key);
        }
    });
}

export const populateDefaultShowPref = (data, prefs) => {

    if (Array.isArray(data) && data.length >= 1) {
        mapPrefProperty(data[0], prefs);
        populateFilterArrayPref(data, prefs)
    }
    else if(data.tabularRecords){
        addPrefKey(prefs, "showHeader", "header");
        mapPrefProperty(data.tabularRecords[0], prefs);
        populateFilterArrayPref(data.tabularRecords, prefs)
    }
    else {
        mapPrefProperty(data, prefs);
    }

    if (prefs && prefs.title === undefined) {
        prefs.title = (data.header || "") + " Settings";
    }

    return prefs;
};
export const populateFilterArrayPref = (data, prefs) => {
    if(prefs && !prefs.hasOwnProperty("filterArray")) {
        prefs['filterArray'] = {
            type: "filterArray",
            value: [],
            enabled: true,
            visible: true,
            displayName: "Filter Items"
        };
        data.map((elem, index) => {
            let key = (elem && elem.indexTitle) || index;
            prefs['filterArray'].value.push([key, true]);
        });
    }
};


