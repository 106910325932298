import React from 'react';

const DownloadTemplates = () => {
    // List of JSON file names without extensions
    const fileNames = ['SimpleBlossom']; // Add all your file names here

    // Function to download a JSON file from the 'pre-built-resumes' folder
    const downloadFile = async (fileName) => {
        try {
            const file = await import(`@/data/pre-built-resumes/${fileName}.json`);
            const dataStr = JSON.stringify(file.default, null, 2);
            const blob = new Blob([dataStr], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.json`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div>
            <h5>Download Pre-Built Resumes</h5>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {fileNames.map((fileName, index) => (
                    <li key={index} style={{ marginBottom: '8px' }}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                downloadFile(fileName);
                            }}
                            style={{
                                textDecoration: 'none',
                                color: '#007BFF',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }}
                        >
                            {fileName.replace(/([A-Z])/g, ' $1').trim()} {/* Format file name */}
                        </a>
                    </li>
                ))}
            </ul>
            <span>
        <i>Once downloaded, you can upload it to the builder and start editing it.</i>
      </span>
        </div>
    );
};

export default DownloadTemplates;
