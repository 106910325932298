import React, { useState, useEffect, useRef } from 'react';

const EditableText = ({ text, onChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(text);
    const spanRef = useRef(null);
    const selectionRef = useRef({ start: 0, end: 0 });

    useEffect(() => {
        setValue(text);
    }, [text]);

    const handleClick = (e) => {
        setIsEditing(true);
        if (spanRef.current.innerText === '\u200B') {
            spanRef.current.innerText = '';
        }
        e.stopPropagation();
    };

    const handleBlur = () => {
        setIsEditing(false);
        onChange(value);
        if (spanRef.current.innerText === '') {
            spanRef.current.innerText = '\u200B';
        }
    };

    const handleInput = (e) => {
        saveSelection();
        setValue(e.target.innerText);
    };

    const saveSelection = () => {
        if (window.getSelection && spanRef.current) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            selectionRef.current = {
                start: range.startOffset,
                end: range.endOffset
            };
        }
    };

    const restoreSelection = () => {
        if (selectionRef.current && window.getSelection && spanRef.current) {
            const range = document.createRange();
            const selection = window.getSelection();

            // Ensure that spanRef.current has child nodes and inner text to work with
            const textNode = spanRef.current.childNodes[0] || spanRef.current;
            const maxOffset = textNode.textContent ? textNode.textContent.length : 0;

            // Calculate a safe offset (ensure it doesn't exceed the length of the text)
            const offset = Math.min(selectionRef.current.start, maxOffset);

            // Safely set start and end based on the available content
            try {
                range.setStart(textNode, offset);
                range.setEnd(textNode, offset);
            } catch (e) {
                console.error("Error setting range:", e);
                return;
            }

            // Remove any existing ranges and apply the new one
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior for Enter key
        }

        if (e.key === 'Backspace' && window.getSelection) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            if (range.startOffset === 0 && range.endOffset === spanRef.current.innerText.length) {
                e.preventDefault();
                setValue('');
                spanRef.current.innerText = '\u200B'; // Add zero-width space to keep it editable
                selection.removeAllRanges();
                setIsEditing(true);
                onChange('');
                setTimeout(() => {
                    restoreSelection();
                }, 0);
            }
        }
    };

    useEffect(() => {
        if (isEditing && spanRef.current) {
            spanRef.current.focus();
            restoreSelection();
        }
    }, [isEditing, value]);

    return (
        <span
            ref={spanRef}
            dir="ltr"
            onClick={handleClick}
            onMouseDown={(e) => { e.stopPropagation(); }}
            onBlur={handleBlur}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            contentEditable={isEditing}
            suppressContentEditableWarning={true}
            className={`editable ${isEditing ? 'editing' : ''}`}
        >
            {value || '\u200B'} {/* Zero-width space to keep the span accessible when empty */}
        </span>
    );
};

export default EditableText;
