'use client';

import defaultPreferences from '@/data/default_preferences.json';
import presets from '@/data/presets_dual_column_page.json';
import allComponents from "@/components/resume/templates/Primo/allComponents.json";
import { v4 as uuidv4 } from 'uuid';
import {intial_page} from "@/constants";

export const addPage = (pages, setPages) => {
    const newColumns = { ...pages.columns, [pages.rows.length]: ['left', 'right'] };
    const newRows = [...pages.rows, { left: [],  right: [] } ];
    const newPages = { columns: newColumns, rows: newRows };
    setPages(newPages);
    return newPages;
};


export const deletePage = (pageIndex, pages, setPages) => {
    const updatedRows = pages.rows.filter((_, rowIndex) => rowIndex !== pageIndex);
    const updatedColumns = Object.keys(pages.columns)
        .filter(key => parseInt(key) !== pageIndex)
        .reduce((acc, key, idx) => {
            acc[idx] = pages.columns[key];
            return acc;
        }, {});

    setPages({ columns: updatedColumns, rows: updatedRows });
};


export const addComponent = (component, pageIndex, colName, pages, setPages) => {
    if (component) {
        const newColumn = [...pages.rows[pageIndex][colName]];
        const newComponent = {
            name: component,
            preferences: defaultPreferences[component] || {},
            id: `${component}-${uuidv4()}`,
            displayName: `${allComponents[component]}`
        };
        newColumn.push(newComponent);
        if(!["spacer"].includes(component)){
            newColumn.push({
                name: "spacer",
                preferences: defaultPreferences["spacer"] || {},
                id: `${"spacer"}-${uuidv4()}`,
                displayName: `${allComponents["spacer"]}`
            });
        }


        const updatedRows = [...pages.rows];
        updatedRows[pageIndex] = {
            ...pages.rows[pageIndex],
            [colName]: newColumn
        };

        setPages({ columns: pages.columns, rows: updatedRows });
    }
};

export const updateComponent = (componentId, newPreferences, pages, setPages) => {
    const updatedRows = pages.rows.map((row) =>
        Object.keys(row).reduce((acc, col) => {
            acc[col] = row[col].map((comp) =>
                comp.id === componentId ? { ...comp, preferences: newPreferences } : comp
            );
            return acc;
        }, {})
    );
    setPages({ ...pages, rows: updatedRows });
};



export const removeComponent = (pageIndex, columnName, componentIndex, pages, setPages) => {
    const columnData = [...pages.rows[pageIndex][columnName]]; // Clone the array
    columnData.splice(componentIndex, 1); // Remove the element at componentIndex

    const updatedRows = [...pages.rows];
    updatedRows[pageIndex] = {
        ...pages.rows[pageIndex],
        [columnName]: columnData
    };

    setPages({ columns: pages.columns, rows: updatedRows });
};


export const handlePresetChange = (preset, setPages) => {
    if (presets[preset]) {
        let updatedPages = { ...intial_page };

        for (let rowIndex = 0; rowIndex < presets[preset].length; rowIndex++) {
            const currentPageConf = presets[preset][rowIndex];
            if (rowIndex > 0) {
                addPage(updatedPages, (newPages)=>{updatedPages=newPages}); // Update state for each page addition
            }
            for (let colName of updatedPages.columns[rowIndex]) {
                for (let component of currentPageConf[colName]) {
                    addComponent(component, rowIndex, colName, updatedPages, (newPages)=>{updatedPages=newPages}); // Update state for each component
                }
            }
        }
        setPages(updatedPages);
        return updatedPages;
    }
};




export const updateAvailableComponents = (pages, allowDuplicates) => {
    if (allowDuplicates) {
        return allComponents;
    } else {
        let usedComponents = new Set();
        // Iterate over each row
        pages.rows.forEach((row, rowIndex) => {
            const columns = pages.columns[rowIndex];
            columns.forEach((colName)=>{
                if(row[colName]){
                    row[colName].forEach(component => usedComponents.add(component['name']));
                }
            });
        });
        // Create a copy of allComponents and remove used components
        const newAvailableComponents = { ...allComponents };
        usedComponents.forEach(component => {
            delete newAvailableComponents[component];
        });
        return newAvailableComponents;
    }
};



export const initializePreferences = (pages, preferences) => {
    const initialPreferences = {};
    pages.forEach(page => {
        page.left.concat(page.right).forEach(component => {
            const componentKey = Object.keys(component)[0];
            const componentId = component[componentKey].id;
            if (!preferences[componentId]) {
                initialPreferences[componentId] = component[componentKey];
            }
        });
    });
    return initialPreferences;
};
