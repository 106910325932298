'use client';

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import resumeData from '@/data/sample_resume.json';
import VersionCheck from '@/utils/VersionCheck';
import Toolbar from '@/components/config/ToolBar';
import ResumeContainer from '@/components/resume/ResumeContainer';
import JsonEditorModal from "@/components/config/JsonEditorModal";
import Modal from "react-modal";
import Footer from "@/components/structure/Footer";

const Home = () => {
    const [pdfOption, setPDFOption] = useState("A4");
    const [jsonData, setJsonData] = useState(()=>{
        if(typeof window !== 'undefined' && typeof sessionStorage !== 'undefined'){
            let savedData = sessionStorage.getItem('resumeData');
            if (savedData) {
                savedData = JSON.parse(savedData);
            } else {
                sessionStorage.setItem('resumeData', JSON.stringify(resumeData));
                savedData = resumeData;
            }
            return savedData;
        }
        else{
            return resumeData;
        }
    });
    const [isJsonEditorOpen, setIsJsonEditorOpen] = useState(false);

    useEffect(()=>{
        if(typeof window !== 'undefined' && typeof sessionStorage !== 'undefined'){
            sessionStorage.setItem('resumeData', JSON.stringify(jsonData));
        }
    }, [jsonData]);

    // Run version check on component mount
    useEffect(() => {
        VersionCheck();
        Modal.setAppElement('#__next');
    }, []);
    const [scale, setScale] = useState(1); // Default scale is 1
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const targetWidth = 900; // Target width for the resume (adjust as needed)
            const pixelRatio = window.devicePixelRatio || 1;
            // const calculatedScale = Math.min(screenWidth / (targetWidth * pixelRatio), 1); // Calculate scaling factor based on screen width
            const calculatedScale = Math.min(screenWidth / targetWidth, 1); // Calculate scaling factor based on screen width
            setScale(calculatedScale);
        };

        // Set the scale initially and on window resize
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <Container style={{
                    '--resume-scale': scale,
                    '--resume-scale-margin-left': `${18 * Math.pow((1-scale)+2, 4)}px`
                }}
            >
                <Toolbar
                    pdfOption={pdfOption}
                    setPDFOption={setPDFOption}
                    toggleDragHandles={() => document.body.classList.toggle('hide-drag-handles')}
                    clearLocalStorage={() => sessionStorage.clear()}
                    />
                <Row>
                    <Col>
                        <JsonEditorModal
                            isJsonEditorOpen={isJsonEditorOpen}
                            setIsJsonEditorOpen={setIsJsonEditorOpen}
                            masterData={jsonData}
                            setMasterData={setJsonData}
                        />
                        <ResumeContainer
                            jsonData={jsonData}
                            pdfOption={pdfOption}
                            openJsonEditor={() => setIsJsonEditorOpen(true)}
                            setJsonData={setJsonData}
                        />
                    </Col>
                </Row>
                <Footer />
            </Container>
        </>
    );
};

export default Home;
